.formBody {
  width: 100%;

  padding: 60px;
}

.formRow {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.inputField {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-color: white;
  padding-left: 5px;
  font-size: 16px;
  color: white;
  border-style: solid;
  border-width: 1.5px;
}

::placeholder {
  color: white;
  opacity: 1;
  font-weight: 200;
}

.leftColumnCell {
  width: 50%;
  padding-right: 5px;
}

.rightColumnCell {
  width: 50%;
  padding-left: 5px;
}
@media screen and (max-width: 940px) {
  .formBody {
    width: 100%;
    margin-top: 10px;
  }
  .formRow {
    flex-direction: column;
  }
  .leftColumnCell {
    width: 100%;
    padding-right: 0;
    margin-bottom: 12px;
  }

  .rightColumnCell {
    width: 100%;
    padding-left: 0px;
  }
}
