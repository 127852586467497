body {
  width: 100%;
  padding: 0;
  margin: 0;
}

.main {
  margin: 0;
  padding: 0;
  width: 100%;
}

.animation {
  display: none;
}

.curtain {
  display: none;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 44px;
  padding-left: 80px;
  margin-bottom: 44px;
  padding-right: 80px;
  align-items: center;
  width: 100%;
}

.logoContainer img {
  width: 310px;
  height: 81px;
}

.openNav {
  display: none;
}

.closeBtn {
  display: none;
}

.buttonContainer {
  margin-left: 10px;
}

.navButton {
  font-size: 22px;
  font-weight: lighter;
  border: 0;
  background-color: transparent;
  margin-left: 10px;
}

.imageContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 740px;
}

.imageBackground {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.4);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.imageContainer .title {
  font-size: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.imageContainer .subTitle {
  margin: 36px;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
}

.startBtn {
  /* background-color: #f47920; */
  background-color: #213e4b;
  border: 0;
  width: 160px;
  height: 50px;
  color: white;
  font-size: 24px;
  font-weight: lighter;
}

.contentContainer {
  margin-top: 100px;
  margin-left: 8%;
  margin-right: 8%;
}

.contentContainer .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  /* color: #f47920; */
  color: #213e4b;
  text-align: center;
}

.contentContainer .text {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 32px;
  margin-bottom: 120px;
  text-align: center;
}

.paragraphContainer {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.paragraphContainer2 {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}

.imageColumn {
  flex: 1;

  min-width: 360px;
}

.imageColumn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textColumn {
  flex: 1;
  background-color: #f5f4f2;
  min-width: 360px;
}

.textColumn .content {
  padding: 10%;
  padding-bottom: 40px;
}

.textColumn .title {
  text-align: center;
  font-size: 24px;
  /* color: #f47920; */
  color: #213e4b;
  margin-bottom: 40px;
}

.textColumn .text {
  font-weight: 200;
}

.textColumn .btnContainer {
  margin-top: 80px;
  text-align: center;
}

.insuranceParagraph {
  /* background-color: #f47920; */
  background-color: #213e4b;
}

.frame {
  padding: 7%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.insuranceTitle {
  text-align: center;
  font-size: 40px;
  /* color: #f47920; */
  color: #213e4b;
}

.insuranceSubtitle {
  font-weight: lighter;
  font-size: 18px;
  text-align: center;
}

.insuranceTitle1 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 600;
}

.insuranceText {
  font-weight: 200;
}

.contactContainer {
  width: 100%;
  height: 100%;
  /* background-color: #f47920; */
  background-color: #213e4b;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.formColumn {
  width: 50%;
  padding: 0px;
  flex: 1;
  min-width: 400px;
  margin-top: 20px;
}

.formColumn .title {
  text-align: center;
  font-size: 40px;
  color: white;
}

.addressText {
  color: white;
  font-weight: lighter;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 940px) {
  .curtain {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    animation: hideCurtain 5s forwards;
  }

  @keyframes hideCurtain {
    100% {
      display: none;
      visibility: hidden;
    }
  }

  .curtainWrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
  }

  .curtainPannel {
    background-color: white;
    width: 50%;
    height: 100vh;
    float: left;
    position: relative;
    z-index: 2;
  }

  .curtainPannelLeft {
    animation: moveLeft 0.5s linear forwards;
  }

  @keyframes moveLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .curtainPannelRight {
    animation: moveRight 0.5s linear forwards;
  }

  @keyframes moveRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .curtainLogo {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #213e4b;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: hideLogoContainer 1.5s forwards;
    animation-delay: 3s;
  }

  @keyframes hideLogoContainer {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }

  .curtainLogo img {
    opacity: 0;
    width: 50%;
    animation-name: showLogo;
    animation-delay: 1s;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  @keyframes showLogo {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 0;
    }
  }

  .navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }

  .logoContainer img {
    width: 202px;
    height: 53px;
    margin-left: 20px;
    margin-right: 0px;
  }

  .openNav {
    display: block;
    margin-right: 20px;
    transition: ease-in-out;
  }

  .closeBtn {
    display: block;
  }

  .buttonContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    height: 100vh;
    padding-top: 50px;
    padding-left: 10px;
    right: -200px;
    background-color: #f5f4f2;
    top: 0;
  }

  .buttonContainerActive {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    height: 100vh;
    padding-top: 50px;
    padding-left: 10px;
    animation: move 0.5s linear forwards;
    right: -200px;
    background-color: #f5f4f2;
    top: 0;
  }

  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200px);
    }
  }

  .closeBtn {
    font-size: 30px;
    width: 100%;
    text-align: end;
    padding-right: 30px;
    margin-bottom: 60px;
  }

  .buttonContainerActive .navButton {
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .contentContainer .text {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 22px;
    font-weight: lighter;
  }

  .imageContainer {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 540px;
  }

  .imageColumn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .textColumn .title {
    font-size: 28px;
  }

  .addressText {
    color: white;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
  }

  .addressText p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }

  .formColumn {
    padding-top: 30px;
  }

  .frame {
    padding: 7px;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 774px) {
  #p5 {
    height: 250px;
  }
}
